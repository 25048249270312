/* SAMPLE COLLECTORS */
export const GET_PAYMENT_STATUSS = "GET_PAYMENT_STATUSS";
export const GET_PAYMENT_STATUSS_SUCCESS =
  "GET_PAYMENT_STATUSS_SUCCESS";
export const GET_PAYMENT_STATUSS_FAIL = "GET_PAYMENT_STATUSS_FAIL";

export const GET_PAYMENTOUT_STATUSS = "GET_PAYMENTOUT_STATUSS";
export const GET_PAYMENTOUT_STATUSS_SUCCESS =
  "GET_PAYMENTOUT_STATUSS_SUCCESS";
export const GET_PAYMENTOUT_STATUSS_FAIL = "GET_PAYMENTOUT_STATUSS_FAIL";

export const GET_DEPOSIT_STATUSS = "GET_DEPOSIT_STATUSS";
export const GET_DEPOSIT_STATUSS_SUCCESS =
  "GET_DEPOSIT_STATUSS_SUCCESS";
export const GET_DEPOSIT_STATUSS_FAIL = "GET_DEPOSIT_STATUSS_FAIL";

export const GET_CREATEDOUT_STATUSS = "GET_CREATEDOUT_STATUSS";
export const GET_CREATEDOUT_STATUSS_SUCCESS =
  "GET_CREATEDOUT_STATUSS_SUCCESS";
export const GET_CREATEDOUT_STATUSS_FAIL = "GET_CREATEDOUT_STATUSS_FAIL";

export const GET_CCREATEDOUT_STATUSS = "GET_CCREATEDOUT_STATUSS";
export const GET_CCREATEDOUT_STATUSS_SUCCESS =
  "GET_CCREATEDOUT_STATUSS_SUCCESS";
export const GET_CCREATEDOUT_STATUSS_FAIL = "GET_CCREATEDOUT_STATUSS_FAIL";

export const GET_CREATEDOUTDETAIL_STATUSS = "GET_CREATEDOUTDETAIL_STATUSS";
export const GET_CREATEDOUTDETAIL_STATUSS_SUCCESS =
  "GET_CREATEDOUTDETAIL_STATUSS_SUCCESS";
export const GET_CREATEDOUTDETAIL_STATUSS_FAIL = "GET_CREATEDOUTDETAIL_STATUSS_FAIL";

export const GET_PAYMENTOUT_CLEAR_STATUSS = "GET_PAYMENTOUT_CLEAR_STATUSS";
export const GET_PAYMENTOUT_CLEAR_STATUSS_SUCCESS =
  "GET_PAYMENTOUT_CLEAR_STATUSS_SUCCESS";
export const GET_PAYMENTOUT_CLEAR_STATUSS_FAIL = "GET_PAYMENTOUT_CLEAR_STATUSS_FAIL";

export const GET_CLEAR_STATUSS = "GET_CLEAR_STATUSS";
export const GET_CLEAR_STATUSS_SUCCESS =
  "GET_CLEAR_STATUSS_SUCCESS";
export const GET_CLEAR_STATUSS_FAIL = "GET_CLEAR_STATUSS_FAIL";

export const GET_BOUNCEDIN_STATUSS = "GET_BOUNCEDIN_STATUSS";
export const GET_BOUNCEDIN_STATUSS_SUCCESS =
  "GET_BOUNCEDIN_STATUSS_SUCCESS";
export const GET_BOUNCEDIN_STATUSS_FAIL = "GET_BOUNCEDIN_STATUSS_FAIL";

export const GET_BOUNCED_STATUSS = "GET_BOUNCED_STATUSS";
export const GET_BOUNCED_STATUSS_SUCCESS =
  "GET_BOUNCED_STATUSS_SUCCESS";
export const GET_BOUNCED_STATUSS_FAIL = "GET_BOUNCED_STATUSS_FAIL";

export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const UPDATE_PAYMENT_STATUS_SUCCESS =
  "UPDATE_PAYMENT_STATUS_SUCCESS";
export const UPDATE_PAYMENT_STATUS_FAIL =
  "UPDATE_PAYMENT_STATUS_FAIL";

export const UPDATE_PAYMENTOUT_STATUS = "UPDATE_PAYMENTOUT_STATUS";
export const UPDATE_PAYMENTOUT_STATUS_SUCCESS =
  "UPDATE_PAYMENTOUT_STATUS_SUCCESS";
export const UPDATE_PAYMENTOUT_STATUS_FAIL =
  "UPDATE_PAYMENTOUT_STATUS_FAIL";

export const UPDATE_PAYMENTIN_STATUS = "UPDATE_PAYMENTIN_STATUS";
export const UPDATE_PAYMENTIN_STATUS_SUCCESS =
  "UPDATE_PAYMENTIN_STATUS_SUCCESS";
export const UPDATE_PAYMENTIN_STATUS_FAIL =
  "UPDATE_PAYMENTOUT_STATUS_FAIL";

export const UPDATE_PAYMENTOUTCREATED_STATUS = "UPDATE_PAYMENTOUTCREATED_STATUS";
export const UPDATE_PAYMENTOUTCREATED_STATUS_SUCCESS =
  "UPDATE_PAYMENTOUTCREATED_STATUS_SUCCESS";
export const UPDATE_PAYMENTOUTCREATED_STATUS_FAIL =
  "UPDATE_PAYMENTOUTCREATED_STATUS_FAIL";

export const UPDATE_PAYMENTOUTCCREATED_STATUS = "UPDATE_PAYMENTOUTCCREATED_STATUS";
export const UPDATE_PAYMENTOUTCCREATED_STATUS_SUCCESS =
  "UPDATE_PAYMENTOUTCCREATED_STATUS_SUCCESS";
export const UPDATE_PAYMENTOUTCCREATED_STATUS_FAIL =
  "UPDATE_PAYMENTOUTCCREATED_STATUS_FAIL";

export const UPDATE_PAYMENTINBOUNCED_STATUS = "UPDATE_PAYMENTINBOUNCED_STATUS";
export const UPDATE_PAYMENTINBOUNCED_STATUS_SUCCESS =
  "UPDATE_PAYMENTINBOUNCED_STATUS_SUCCESS";
export const UPDATE_PAYMENTINBOUNCED_STATUS_FAIL =
  "UPDATE_PAYMENTINBOUNCED_STATUS_FAIL";

// export const ADD_NEW_OUT_PAYMENT = "ADD_NEW_OUT_PAYMENT";
// export const ADD_OUT_PAYMENT_SUCCESS =
//   "ADD_OUT_PAYMENT_SUCCESS";
// export const ADD_OUT_PAYMENT_FAIL = "ADD_OUT_PAYMENT_FAIL";

export const GET_LABS = "get_labs";
export const GET_LABS_SUCCESS = "get_labs_success";
export const GET_LABS_FAIL = "get_labs_fail";

export const GET_DONORS = "get_donors";
export const GET_DONORS_SUCCESS = "get_donors_success";
export const GET_DONORS_FAIL = "get_donors_fail";

export const GET_BANKS = "get_banks";
export const GET_BANKS_SUCCESS = "get_banks_success";
export const GET_BANKS_FAIL = "get_banks_fail";

export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const GET_BANK_ACCOUNTS_SUCCESS =
  "GET_BANK_ACCOUNTS_SUCCESS";
export const GET_BANK_ACCOUNTS_FAIL = "GET_BANK_ACCOUNTS_FAIL";

export const DELETE_PAYMENTOUT = "DELETE_PAYMENTOUT";
export const DELETE_PAYMENTOUT_SUCCESS = "DELETE_PAYMENTOUT_SUCCESS";
export const DELETE_PAYMENTOUT_FAIL = "DELETE_PAYMENTOUT_FAIL";
