/* LAB PROFILE */
export const GET_LAB_PROFILE = "GET_LAB_PROFILE";
export const GET_LAB_PROFILE_SUCCESS = "GET_LAB_PROFILE_SUCCESS";
export const GET_LAB_PROFILE_FAIL = "GET_LAB_PROFILE_FAIL";


export const UPDATE_LAB_PROFILE = "UPDATE_LAB_PROFILE";
export const UPDATE_LAB_PROFILE_SUCCESS =
  "UPDATE_LAB_PROFILE_SUCCESS";
export const UPDATE_LAB_PROFILE_FAIL = "UPDATE_LAB_PROFILE_FAIL";

