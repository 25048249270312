/* SAMPLE COLLECTOR TEST APPOINTMENTtS */
export const GET_SAMPLE_COLLECTION_IN_PROCESS_LIST =
  "GET_SAMPLE_COLLECTION_IN_PROCESS_LIST";
export const GET_SAMPLE_COLLECTION_IN_PROCESS_LIST_SUCCESS =
  "GET_SAMPLE_COLLECTION_IN_PROCESS_LIST_SUCCESS";
export const GET_SAMPLE_COLLECTION_IN_PROCESS_LIST_FAIL =
  "GET_SAMPLE_COLLECTION_IN_PROCESS_LIST_FAIL";

export const GET_SAMPLE_COLLECTION_COMPLETED_LIST =
  "GET_SAMPLE_COLLECTION_COMPLETED_LIST";
export const GET_SAMPLE_COLLECTION_COMPLETED_LIST_SUCCESS =
  "GET_SAMPLE_COLLECTION_COMPLETED_LIST_SUCCESS";
export const GET_SAMPLE_COLLECTION_COMPLETED_LIST_FAIL =
  "GET_SAMPLE_COLLECTION_COMPLETED_LIST_FAIL";

export const UPDATE_SAMPLE_COLLECTION_STATUS =
  "UPDATE_SAMPLE_COLLECTION_STATUS";
export const UPDATE_SAMPLE_COLLECTION_STATUS_SUCCESS =
  "UPDATE_SAMPLE_COLLECTION_STATUS_SUCCESS";
export const UPDATE_SAMPLE_COLLECTION_STATUS_FAIL =
  "UPDATE_SAMPLE_COLLECTION_STATUS_FAIL";
