/*  PATIENT TEST APPOINTMENTtS */

export const GET_LABS_LIST_PENDING_FEE =
  "GET_LABS_LIST_PENDING_FEE";
export const GET_LABS_LIST_PENDING_FEE_SUCCESS =
  "GET_LABS_LIST_PENDING_FEE_SUCCESS";
export const GET_LABS_LIST_PENDING_FEE_FAIL =
  "GET_LABS_LIST_PENDING_FEE_FAIL";

export const GET_LABS_LIST_APPROVED_FEE =
  "GET_LABS_LIST_APPROVED_FEE";
export const GET_LABS_LIST_APPROVED_FEE_SUCCESS =
  "GET_LABS_LIST_APPROVED_FEE_SUCCESS";
export const GET_LABS_LIST_APPROVED_FEE_FAIL =
  "GET_LABS_LIST_APPROVED_FEE_FAIL";