/* SAMPLE COLLECTORS */
export const GET_MSGS = "GET_MSGS";
export const GET_MSGS_SUCCESS =
  "GET_MSGS_SUCCESS";
export const GET_MSGS_FAIL = "GET_MSGS_FAIL";

export const ADD_NEW_MSG = "ADD_NEW_MSG";
export const ADD_MSG_SUCCESS =
  "ADD_MSG_SUCCESS";
export const ADD_MSG_FAIL = "ADD_MSG_FAIL";

