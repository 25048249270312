/* COMPLAINTS */
export const ADD_NEW_COMPLAINT = "ADD_NEW_COMPLAINT";
export const ADD_COMPLAINT_SUCCESS = "ADD_COMPLAINT_SUCCESS";
export const ADD_COMPLAINT_FAIL = "ADD_COMPLAINT_FAIL";

export const GET_LABS = "get_labs";
export const GET_LABS_SUCCESS = "get_labs_success";
export const GET_LABS_FAIL = "get_labs_fail";

export const GET_HANDLED_COMPLAINTS = "GET_HANDLED_COMPLAINTS";
export const GET_HANDLED_COMPLAINTS_SUCCESS = "GET_HANDLED_COMPLAINTS_SUCCESS";
export const GET_HANDLED_COMPLAINTS_FAIL = "GET_HANDLED_COMPLAINTS_FAIL";

export const GET_UNHANDLED_COMPLAINTS = "GET_UNHANDLED_COMPLAINTS";
export const GET_UNHANDLED_COMPLAINTS_SUCCESS =
  "GET_UNHANDLED_COMPLAINTS_SUCCESS";
export const GET_UNHANDLED_COMPLAINTS_FAIL = "GET_UNHANDLED_COMPLAINTS_FAIL";

export const UPDATE_UNHANDLED_COMPLAINTS = "UPDATE_UNHANDLED_COMPLAINTS";
export const UPDATE_UNHANDLED_COMPLAINTS_SUCCESS =
  "UPDATE_UNHANDLED_COMPLAINTS_SUCCESS";
export const UPDATE_UNHANDLED_COMPLAINTS_FAIL = "UPDATE_UNHANDLED_COMPLAINTS_FAIL";
