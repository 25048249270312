export const GET_LABS = "get_labs";
export const GET_LABS_SUCCESS = "get_labs_success";
export const GET_LABS_FAIL = "get_labs_fail";


/*  SHARES */

export const GET_B2B_LAB_SHARES_LIST =
  "GET_B2B_LAB_SHARES_LIST";
export const GET_B2B_LAB_SHARES_LIST_SUCCESS =
  "GET_B2B_LAB_SHARES_LIST_SUCCESS";
export const GET_B2B_LAB_SHARES_LIST_FAIL =
  "GET_B2B_LAB_SHARES_LIST_FAIL";

export const GET_B2B_SHARES_LAB_LIST =
  "GET_B2B_SHARES_LAB_LIST";
export const GET_B2B_SHARES_LAB_LIST_SUCCESS =
  "GET_B2B_SHARES_LAB_LIST_SUCCESS";
export const GET_B2B_SHARES_LAB_LIST_FAIL =
  "GET_B2B_SHARES_LAB_LIST_FAIL";

/* SHARES */
export const UPDATE_LAB_SHARE = "UPDATE_LAB_SHARE";
export const UPDATE_LAB_SHARE_SUCCESS = "UPDATE_LAB_SHARE_SUCCESS";
export const UPDATE_LAB_SHARE_FAIL = "UPDATE_LAB_SHARE_FAIL";

/* SHARES */
export const UPDATE_ALL_LAB_SHARE = "UPDATE_ALL_LAB_SHARE";
export const UPDATE_ALL_LAB_SHARE_SUCCESS = "UPDATE_ALL_LAB_SHARE_SUCCESS";
export const UPDATE_ALL_LAB_SHARE_FAIL = "UPDATE_ALL_LAB_SHARE_FAIL";


export const ADD_NEW_LAB_SHARE = "ADD_NEW_LAB_SHARE";
export const ADD_LAB_SHARE_SUCCESS = "ADD_LAB_SHARE_SUCCESS";
export const ADD_LAB_SHARE_FAIL = "ADD_LAB_SHARE_FAIL";