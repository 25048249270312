/* PRODUCTS */
export const GET_NEARBY_PROFILES = "GET_NEARBY_PROFILES";
export const GET_NEARBY_PROFILES_SUCCESS = "GET_NEARBY_PROFILES_SUCCESS";
export const GET_NEARBY_PROFILES_FAIL = "GET_NEARBY_PROFILES_FAIL";

export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL";

export const GET_TESTSS = "GET_TESTSS";
export const GET_TESTSS_SUCCESS = "GET_TESTSS_SUCCESS";
export const GET_TESTSS_FAIL = "GET_TESTSS_FAIL";

export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";

