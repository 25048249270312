/*  PATIENT TEST APPOINTMENTtS */

export const GET_LABS_LIST = "GET_LABS_LIST";
export const GET_LABS_LIST_SUCCESS = "GET_LABS_LIST_SUCCESS";
export const GET_LABS_LIST_FAIL = "GET_LABS_LIST_FAIL";

export const GET_LABS_AUDIT_LIST = "GET_LABS_AUDIT_LIST";
export const GET_LABS_AUDIT_LIST_SUCCESS = "GET_LABS_AUDIT_LIST_SUCCESS";
export const GET_LABS_AUDIT_LIST_FAIL = "GET_LABS_AUDIT_LIST_FAIL";

export const GET_LABS_ALL_AUDIT_LIST = "GET_LABS_ALL_AUDIT_LIST";
export const GET_LABS_ALL_AUDIT_LIST_SUCCESS = "GET_LABS_ALL_AUDIT_LIST_SUCCESS";
export const GET_LABS_ALL_AUDIT_LIST_FAIL = "GET_LABS_ALL_AUDIT_LIST_FAIL";
/////////////
export const GET_LC_LIST = "GET_LC_LIST";
export const GET_LC_LIST_SUCCESS = "GET_LC_LIST_SUCCESS";
export const GET_LC_LIST_FAIL = "GET_LC_LIST_FAIL";
/////////////////////
export const GET_DONORS_LIST = "GET_DONORS_LIST";
export const GET_DONORS_LIST_SUCCESS = "GET_DONORS_LIST_SUCCESS";
export const GET_DONORS_LIST_FAIL = "GET_DONORS_LIST_FAIL";

export const GET_CORPORATE_LIST = "GET_CORPORATE_LIST";
export const GET_CORPORATE_LIST_SUCCESS = "GET_CORPORATE_LIST_SUCCESS";
export const GET_CORPORATE_LIST_FAIL = "GET_CORPORATE_LIST_FAIL";

export const GET_DONORSA = "GET_DONORSA";
export const GET_DONORSA_SUCCESS = "GET_DONORSA_SUCCESS";
export const GET_DONORSA_FAIL = "GET_DONORSA_FAIL";

