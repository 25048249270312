/* ADVERTISEMENT LAB REQUEST TO LABHAZIR */

export const GET_LAB_ADVERTISEMENT_REQUESTS = "GET_LAB_ADVERTISEMENT_REQUESTS";
export const GET_LAB_ADVERTISEMENT_REQUESTS_SUCCESS =
  "GET_LAB_ADVERTISEMENT_REQUESTS_SUCCESS";
export const GET_LAB_ADVERTISEMENT_REQUESTS_FAIL = "GET_LAB_ADVERTISEMENT_REQUESTS_FAIL";

export const GET_LAB_ADVERTISEMENT_REQUESTS_ACCEPTED = "GET_LAB_ADVERTISEMENT_REQUESTS_ACCEPTED";
export const GET_LAB_ADVERTISEMENT_REQUESTS_ACCEPTED_SUCCESS =
  "GET_LAB_ADVERTISEMENT_REQUESTS_ACCEPTED_SUCCESS";
export const GET_LAB_ADVERTISEMENT_REQUESTS_ACCEPTED_FAIL = "GET_LAB_ADVERTISEMENT_REQUESTS_ACCEPTED_FAIL";


export const UPDATE_LAB_ADVERTISEMENT_REQUEST = "UPDATE_LAB_ADVERTISEMENT_REQUEST";
export const UPDATE_LAB_ADVERTISEMENT_REQUEST_SUCCESS =
  "UPDATE_LAB_ADVERTISEMENT_REQUEST_SUCCESS";
export const UPDATE_LAB_ADVERTISEMENT_REQUEST_FAIL =
  "UPDATE_LAB_ADVERTISEMENT_REQUEST_FAIL";

