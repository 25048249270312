/* ADVERTISEMENT LAB */

// export const GET_ADVERTISEMENT_PRICE_LISTS = "GET_ADVERTISEMENT_PRICE_LISTS";
// export const GET_ADVERTISEMENT_PRICE_LISTS_SUCCESS =
//   "GET_ADVERTISEMENT_PRICE_LISTS_SUCCESS";
// export const GET_ADVERTISEMENT_PRICE_LISTS_FAIL = "GET_ADVERTISEMENT_PRICE_LISTS_FAIL";

export const GET_LAB_ADVERTISEMENTS = "GET_LAB_ADVERTISEMENTS";
export const GET_LAB_ADVERTISEMENTS_SUCCESS =
  "GET_LAB_ADVERTISEMENTS_SUCCESS";
export const GET_LAB_ADVERTISEMENTS_FAIL = "GET_LAB_ADVERTISEMENTS_FAIL";

export const ADD_NEW_LAB_ADVERTISEMENT = "ADD_NEW_LAB_ADVERTISEMENT";
export const ADD_LAB_ADVERTISEMENT_SUCCESS =
  "ADD_LAB_ADVERTISEMENT_SUCCESS";
export const ADD_LAB_ADVERTISEMENT_FAIL = "ADD_LAB_ADVERTISEMENT_FAIL";

export const UPDATE_LAB_ADVERTISEMENT = "UPDATE_LAB_ADVERTISEMENT";
export const UPDATE_LAB_ADVERTISEMENT_SUCCESS =
  "UPDATE_LAB_ADVERTISEMENT_SUCCESS";
export const UPDATE_LAB_ADVERTISEMENT_FAIL =
  "UPDATE_LAB_ADVERTISEMENT_FAIL";

export const DELETE_LAB_ADVERTISEMENT = "DELETE_LAB_ADVERTISEMENT";
export const DELETE_LAB_ADVERTISEMENT_SUCCESS =
  "DELETE_LAB_ADVERTISEMENT_SUCCESS";
export const DELETE_LAB_ADVERTISEMENT_FAIL =
  "DELETE_LAB_ADVERTISEMENT_FAIL";
