/* LAB PROFILE */
export const GET_DONOR_PROFILE = "GET_DONOR_PROFILE";
export const GET_DONOR_PROFILE_SUCCESS = "GET_DONOR_PROFILE_SUCCESS";
export const GET_DONOR_PROFILE_FAIL = "GET_DONOR_PROFILE_FAIL";


export const UPDATE_DONOR_PROFILE = "UPDATE_DONOR_PROFILE";
export const UPDATE_DONOR_PROFILE_SUCCESS =
  "UPDATE_DONOR_PROFILE_SUCCESS";
export const UPDATE_DONOR_PROFILE_FAIL = "UPDATE_DONOR_PROFILE_FAIL";

