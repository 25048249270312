/* SAMPLE COLLECTORS */
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const GET_FEEDBACKS_SUCCESS =
  "GET_FEEDBACKS_SUCCESS";
export const GET_FEEDBACKS_FAIL = "GET_FEEDBACKS_FAIL";


/* LAB PROFILE */
export const GET_LAB_PROFILE = "GET_LAB_PROFILE";
export const GET_LAB_PROFILE_SUCCESS = "GET_LAB_PROFILE_SUCCESS";
export const GET_LAB_PROFILE_FAIL = "GET_LAB_PROFILE_FAIL";
