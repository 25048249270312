/*  PATIENT TEST APPOINTMENTtS */

export const GET_CSR_CENTRAL_TERRITORY_LIST =
  "GET_CSR_CENTRAL_TERRITORY_LIST";
export const GET_CSR_CENTRAL_TERRITORY_LIST_SUCCESS =
  "GET_CSR_CENTRAL_TERRITORY_LIST_SUCCESS";
export const GET_CSR_CENTRAL_TERRITORY_LIST_FAIL =
  "GET_CSR_CENTRAL_TERRITORY_LIST_FAIL";

// CSR SOUTH
export const GET_CSR_SOUTH_TERRITORY_LIST =
  "GET_CSR_SOUTH_TERRITORY_LIST";
export const GET_CSR_SOUTH_TERRITORY_LIST_SUCCESS =
  "GET_CSR_SOUTH_TERRITORY_LIST_SUCCESS";
export const GET_CSR_SOUTH_TERRITORY_LIST_FAIL =
  "GET_CSR_SOUTH_TERRITORY_LIST_FAIL";

// CSR North
export const GET_CSR_NORTH_TERRITORY_LIST =
  "GET_CSR_NORTH_TERRITORY_LIST";
export const GET_CSR_NORTH_TERRITORY_LIST_SUCCESS =
  "GET_CSR_NORTH_TERRITORY_LIST_SUCCESS";
export const GET_CSR_NORTH_TERRITORY_LIST_FAIL =
  "GET_CSR_NORTH_TERRITORY_LIST_FAIL";
