/* LAB PROFILE */
export const GET_B2B_PROFILE = "GET_B2B_PROFILE";
export const GET_B2B_PROFILE_SUCCESS = "GET_B2B_PROFILE_SUCCESS";
export const GET_B2B_PROFILE_FAIL = "GET_B2B_PROFILE_FAIL";


export const UPDATE_B2B_PROFILE = "UPDATE_B2B_PROFILE";
export const UPDATE_B2B_PROFILE_SUCCESS =
  "UPDATE_B2B_PROFILE_SUCCESS";
export const UPDATE_B2B_PROFILE_FAIL = "UPDATE_B2B_PROFILE_FAIL";

