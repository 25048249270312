/*  PATIENT TEST APPOINTMENTtS */

export const GET_LABS_LIST =
  "GET_LABS_LIST";
export const GET_LABS_LIST_SUCCESS =
  "GET_LABS_LIST_SUCCESS";
export const GET_LABS_LIST_FAIL =
  "GET_LABS_LIST_FAIL";
  export const GET_DONORS_LIST =
  "GET_DONORS_LIST";
export const GET_DONORS_LIST_SUCCESS =
  "GET_DONORS_LIST_SUCCESS";
export const GET_DONORS_LIST_FAIL =
  "GET_DONORS_LIST_FAIL";
  export const GET_DONORSA = "GET_DONORSA";
  export const GET_DONORSA_SUCCESS = "GET_DONORSA_SUCCESS";
  export const GET_DONORSA_FAIL = "GET_DONORSA_FAIL";


  