/* PATIENT PROFILE */
export const GET_PATIENT_PROFILE = "GET_PATIENT_PROFILE";
export const GET_PATIENT_PROFILE_SUCCESS = "GET_PATIENT_PROFILE_SUCCESS";
export const GET_PATIENT_PROFILE_FAIL = "GET_PATIENT_PROFILE_FAIL";


export const UPDATE_PATIENT_PROFILE = "UPDATE_PATIENT_PROFILE";
export const UPDATE_PATIENT_PROFILE_SUCCESS =
  "UPDATE_PATIENT_PROFILE_SUCCESS";
export const UPDATE_PATIENT_PROFILE_FAIL = "UPDATE_PATIENT_PROFILE_FAIL";

