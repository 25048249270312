/* PRODUCTS */
export const GET_NEARBY_RADIOLOGY = "GET_NEARBY_RADIOLOGY";
export const GET_NEARBY_RADIOLOGY_SUCCESS = "GET_NEARBY_RADIOLOGY_SUCCESS";
export const GET_NEARBY_RADIOLOGY_FAIL = "GET_NEARBY_RADIOLOGY_FAIL";

export const GET_RADIOLOGY = "GET_RADIOLOGY";
export const GET_RADIOLOGY_SUCCESS = "GET_RADIOLOGY_SUCCESS";
export const GET_RADIOLOGY_FAIL = "GET_RADIOLOGY_FAIL";

export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";