export const ADD_DONOR_INFORMATION = "add_donor_information"
export const ADD_DONOR_INFORMATION_SUCCESSFUL = "add_donor_information_successfull"
export const ADD_DONOR_INFORMATION_FAILED = "add_donor_information_failed"

// Territories
export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";