/* TESTS AVAILABLE */
export const GET_TESTS = "GET_TESTS";
export const GET_TESTS_SUCCESS = "GET_TESTS_SUCCESS";
export const GET_TESTS_FAIL = "GET_TESTS_FAIL";

/* UNITS LIST */
export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";
export const GET_UNITS_FAIL = "GET_UNITS_FAIL";

export const GET_LAB_PROFILE = "GET_LAB_PROFILE";
export const GET_LAB_PROFILE_SUCCESS = "GET_LAB_PROFILE_SUCCESS";
export const GET_LAB_PROFILE_FAIL = "GET_LAB_PROFILE_FAIL";

/* OFFERED TESTS */
export const GET_OFFERED_TESTS = "GET_OFFERED_TESTS";
export const GET_OFFERED_TESTS_SUCCESS = "GET_OFFERED_TESTS_SUCCESS";
export const GET_OFFERED_TESTS_FAIL = "GET_OFFERED_TESTS_FAIL";

export const GET_CORPORATE_TESTS = "GET_CORPORATE_TESTS";
export const GET_CORPORATE_TESTS_SUCCESS = "GET_CORPORATE_TESTS_SUCCESS";
export const GET_CORPORATE_TESTS_FAIL = "GET_CORPORATE_TESTS_FAIL";

export const GET_OFFEREDTEST_REFERRELFEE = "GET_OFFEREDTEST_REFERRELFEE";
export const GET_OFFEREDTEST_REFERRELFEE_SUCCESS = "GET_OFFEREDTEST_REFERRELFEE_SUCCESS";
export const GET_OFFEREDTEST_REFERRELFEE_FAIL = "GET_OFFEREDTEST_REFERRELFEE_FAIL";

export const GET_COFFEREDPROFILE_REFERRELFEE = "GET_COFFEREDPROFILE_REFERRELFEE";
export const GET_COFFEREDPROFILE_REFERRELFEE_SUCCESS = "GET_COFFEREDPROFILE_REFERRELFEE_SUCCESS";
export const GET_COFFEREDPROFILE_REFERRELFEE_FAIL = "GET_COFFEREDPROFILE_REFERRELFEE_FAIL";

export const GET_COFFEREDPACKAGE_REFERRELFEE = "GET_COFFEREDPACKAGE_REFERRELFEE";
export const GET_COFFEREDPACKAGE_REFERRELFEE_SUCCESS = "GET_COFFEREDPACKAGE_REFERRELFEE_SUCCESS";
export const GET_COFFEREDPACKAGE_REFERRELFEE_FAIL = "GET_COFFEREDPACKAGE_REFERRELFEE_FAIL";

export const GET_COFFEREDRADIOLOGY_REFERRELFEE = "GET_COFFEREDRADIOLOGY_REFERRELFEE";
export const GET_COFFEREDRADIOLOGY_REFERRELFEE_SUCCESS = "GET_COFFEREDRADIOLOGY_REFERRELFEE_SUCCESS";
export const GET_COFFEREDRADIOLOGY_REFERRELFEE_FAIL = "GET_COFFEREDRADIOLOGY_REFERRELFEE_FAIL";

export const GET_COFFEREDTEST_REFERRELFEE = "GET_COFFEREDTEST_REFERRELFEE";
export const GET_COFFEREDTEST_REFERRELFEE_SUCCESS = "GET_COFFEREDTEST_REFERRELFEE_SUCCESS";
export const GET_COFFEREDTEST_REFERRELFEE_FAIL = "GET_COFFEREDTEST_REFERRELFEE_FAIL";

export const GET_OFFEREDPROFILE_REFERRELFEE = "GET_OFFEREDPROFILE_REFERRELFEE";
export const GET_OFFEREDPROFILE_REFERRELFEE_SUCCESS = "GET_OFFEREDPROFILE_REFERRELFEE_SUCCESS";
export const GET_OFFEREDPROFILE_REFERRELFEE_FAIL = "GET_OFFEREDPROFILE_REFERRELFEE_FAIL";

export const GET_OFFEREDPACKAGE_REFERRELFEE = "GET_OFFEREDPACKAGE_REFERRELFEE";
export const GET_OFFEREDPACKAGE_REFERRELFEE_SUCCESS = "GET_OFFEREDPACKAGE_REFERRELFEE_SUCCESS";
export const GET_OFFEREDPACKAGE_REFERRELFEE_FAIL = "GET_OFFEREDPACKAGE_REFERRELFEE_FAIL";

export const GET_OFFEREDRADIOLOGY_REFERRELFEE = "GET_OFFEREDRADIOLOGY_REFERRELFEE";
export const GET_OFFEREDRADIOLOGY_REFERRELFEE_SUCCESS = "GET_OFFEREDRADIOLOGY_REFERRELFEE_SUCCESS";
export const GET_OFFEREDRADIOLOGY_REFERRELFEE_FAIL = "GET_OFFEREDRADIOLOGY_REFERRELFEE_FAIL";

export const ADD_NEW_OFFERED_TEST = "ADD_NEW_OFFERED_TEST";
export const ADD_OFFERED_TEST_SUCCESS = "ADD_OFFERED_TEST_SUCCESS";
export const ADD_OFFERED_TEST_FAIL = "ADD_OFFERED_TEST_FAIL";

export const ADD_NEW_CORPORATE_TEST = "ADD_NEW_CORPORATE_TEST";
export const ADD_CORPORATE_TEST_SUCCESS = "ADD_CORPORATE_TEST_SUCCESS";
export const ADD_CORPORATE_TEST_FAIL = "ADD_CORPORATE_TEST_FAIL";

export const ADD_NEW_CORPORATE = "ADD_NEW_CORPORATE";
export const ADD_CORPORATE_SUCCESS = "ADD_CORPORATE_SUCCESS";
export const ADD_CORPORATE_FAIL = "ADD_CORPORATE_FAIL";

export const ADD_NEW_OFFERED_MAINTEST = "ADD_NEW_OFFERED_MAINTEST";
export const ADD_OFFERED_MAINTEST_SUCCESS = "ADD_OFFERED_MAINTEST_SUCCESS";
export const ADD_OFFERED_MAINTEST_FAIL = "ADD_OFFERED_MAINTEST_FAIL";

export const UPDATE_OFFERED_TEST = "UPDATE_OFFERED_TEST";
export const UPDATE_OFFERED_TEST_SUCCESS = "UPDATE_OFFERED_TEST_SUCCESS";
export const UPDATE_OFFERED_TEST_FAIL = "UPDATE_OFFERED_TEST_FAIL";

export const UPDATE_CORPORATE_TEST = "UPDATE_CORPORATE_TEST";
export const UPDATE_CORPORATE_TEST_SUCCESS = "UPDATE_CORPORATE_TEST_SUCCESS";
export const UPDATE_CORPORATE_TEST_FAIL = "UPDATE_CORPORATE_TEST_FAIL";

export const UPDATE_CORPORATE_STATUS = "UPDATE_CORPORATE_STATUS";
export const UPDATE_CORPORATE_STATUS_SUCCESS = "UPDATE_CORPORATE_STATUS_SUCCESS";
export const UPDATE_CORPORATE_STATUS_FAIL = "UPDATE_CORPORATE_STATUS_FAIL";

export const UPDATE_ACORPORATE_STATUS = "UPDATE_ACORPORATE_STATUS";
export const UPDATE_ACORPORATE_STATUS_SUCCESS = "UPDATE_ACORPORATE_STATUS_SUCCESS";
export const UPDATE_ACORPORATE_STATUS_FAIL = "UPDATE_ACORPORATE_STATUS_FAIL";

export const DELETE_OFFERED_TEST = "DELETE_OFFERED_TEST";
export const DELETE_OFFERED_TEST_SUCCESS = "DELETE_OFFERED_TEST_SUCCESS";
export const DELETE_OFFERED_TEST_FAIL = "DELETE_OFFERED_TEST_FAIL";
