/* CORPORATES */
export const GET_LABCORPORATE = "GET_LABCORPORATE";
export const GET_LABCORPORATE_SUCCESS = "GET_LABCORPORATE_SUCCESS";
export const GET_LABCORPORATE_FAIL = "GET_LABCORPORATE_FAIL";

export const GET_ALABCORPORATE = "GET_ALABCORPORATE";
export const GET_ALABCORPORATE_SUCCESS = "GET_ALABCORPORATE_SUCCESS";
export const GET_ALABCORPORATE_FAIL = "GET_ALABCORPORATE_FAIL";

export const GET_ACORPORATE = "GET_ACORPORATE";
export const GET_ACORPORATE_SUCCESS = "GET_ACORPORATE_SUCCESS";
export const GET_ACORPORATE_FAIL = "GET_ACORPORATE_FAIL";

export const GET_RFEECORPORATE = "GET_RFEECORPORATE";
export const GET_RFEECORPORATE_SUCCESS = "GET_RFEECORPORATE_SUCCESS";
export const GET_RFEECORPORATE_FAIL = "GET_RFEECORPORATE_FAIL";

export const GET_EMPLOYEECORPORATE = "GET_EMPLOYEECORPORATE";
export const GET_EMPLOYEECORPORATE_SUCCESS = "GET_EMPLOYEECORPORATE_SUCCESS";
export const GET_EMPLOYEECORPORATE_FAIL = "GET_EMPLOYEECORPORATE_FAIL";

export const GET_LABSCORPORATE = "GET_LABSCORPORATE";
export const GET_LABSCORPORATE_SUCCESS = "GET_LABSCORPORATE_SUCCESS";
export const GET_LABSCORPORATE_FAIL = "GET_LABSCORPORATE_FAIL";

export const ADD_NEW_CEMPLOYEE_DATA = "ADD_NEW_CEMPLOYEE_DATA";
export const ADD_CEMPLOYEE_DATA_SUCCESS =
  "ADD_CEMPLOYEE_DATA_SUCCESS";
export const ADD_CEMPLOYEE_DATA_FAIL = "ADD_CEMPLOYEE_DATA_FAIL";

export const ADD_NEW_CEMPLOYEE_FILE = "ADD_NEW_CEMPLOYEE_FILE";
export const ADD_CEMPLOYEE_FILE_SUCCESS =
  "ADD_CEMPLOYEE_FILE_SUCCESS";
export const ADD_CEMPLOYEE_FILE_FAIL = "ADD_CEMPLOYEE_FILE_FAIL";


export const UPDATE_CEMPLOYEE = "UPDATE_CEMPLOYEE";
export const UPDATE_CEMPLOYEE_SUCCESS =
  "UPDATE_CEMPLOYEE_SUCCESS";
export const UPDATE_CEMPLOYEE_FAIL =
  "UPDATE_CEMPLOYEE_FAIL";

export const DELETE_CEDATA = "DELETE_CEDATA";
export const DELETE_CEDATA_SUCCESS = "DELETE_CEDATA_SUCCESS";
export const DELETE_CEDATA_FAIL = "DELETE_CEDATA_FAIL";