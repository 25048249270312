/* SAMPLE COLLECTORS */
export const GET_SAMPLE_COLLECTORS = "GET_SAMPLE_COLLECTORS";
export const GET_SAMPLE_COLLECTORS_SUCCESS = "GET_SAMPLE_COLLECTORS_SUCCESS";
export const GET_SAMPLE_COLLECTORS_FAIL = "GET_SAMPLE_COLLECTORS_FAIL";

export const ADD_NEW_SAMPLE_COLLECTOR = "ADD_NEW_SAMPLE_COLLECTOR";
export const ADD_SAMPLE_COLLECTOR_SUCCESS = "ADD_SAMPLE_COLLECTOR_SUCCESS";
export const ADD_SAMPLE_COLLECTOR_FAIL = "ADD_SAMPLE_COLLECTOR_FAIL";

export const UPDATE_SAMPLE_COLLECTOR = "UPDATE_SAMPLE_COLLECTOR";
export const UPDATE_SAMPLE_COLLECTOR_SUCCESS =
  "UPDATE_SAMPLE_COLLECTOR_SUCCESS";
export const UPDATE_SAMPLE_COLLECTOR_FAIL = "UPDATE_SAMPLE_COLLECTOR_FAIL";

export const DELETE_SAMPLE_COLLECTOR = "DELETE_SAMPLE_COLLECTOR";
export const DELETE_SAMPLE_COLLECTOR_SUCCESS =
  "DELETE_SAMPLE_COLLECTOR_SUCCESS";
export const DELETE_SAMPLE_COLLECTOR_FAIL = "DELETE_SAMPLE_COLLECTOR_FAIL";
