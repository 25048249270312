/* STAFF PROFILE */
export const GET_ASSIGNED_AUDITS = "GET_ASSIGNED_AUDITS";
export const GET_ASSIGNED_AUDITS_SUCCESS = "GET_ASSIGNED_AUDITS_SUCCESS";
export const GET_ASSIGNED_AUDITS_FAIL = "GET_ASSIGNED_AUDITS_FAIL";

export const GET_LAB_AUDITS = "GET_LAB_AUDITS";
export const GET_LAB_AUDITS_SUCCESS = "GET_LAB_AUDITS_SUCCESS";
export const GET_LAB_AUDITS_FAIL = "GET_LAB_AUDITS_FAIL";

export const ADD_NEW_AUDIT = "ADD_NEW_AUDIT";
export const ADD_AUDIT_SUCCESS = "ADD_AUDIT_SUCCESS";
export const ADD_AUDIT_FAIL = "ADD_AUDIT_FAIL";

export const UPDATE_ASSIGNED_AUDITS = "UPDATE_ASSIGNED_AUDITS";
export const UPDATE_ASSIGNED_AUDITS_SUCCESS = "UPDATE_ASSIGNED_AUDITS_SUCCESS";
export const UPDATE_ASSIGNED_AUDITS_FAIL = "UPDATE_ASSIGNED_AUDITS_FAIL";

export const GET_AUDITORS_COMPLETED_AUDITS = "GET_AUDITORS_COMPLETED_AUDITS";
export const GET_AUDITORS_COMPLETED_AUDITS_SUCCESS = "GET_AUDITORS_COMPLETED_AUDITS_SUCCESS";
export const GET_AUDITORS_COMPLETED_AUDITS_FAIL = "GET_AUDITORS_COMPLETED_AUDITS_FAIL";

export const UPDATE_AUDITS_STATUS = "UPDATE_AUDITS_STATUS";
export const UPDATE_AUDITS_STATUS_SUCCESS = "UPDATE_AUDITS_STATUS_SUCCESS";
export const UPDATE_AUDITS_STATUS_FAIL = "UPDATE_AUDITS_STATUS_FAIL";