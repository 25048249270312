/*  PATIENT TEST APPOINTMENTtS */

export const GET_DISCOUNT_LAB =
  "GET_DISCOUNT_LAB";
export const GET_DISCOUNT_LAB_SUCCESS =
  "GET_DISCOUNT_LAB_SUCCESS";
export const GET_DISCOUNT_LAB_FAIL =
  "GET_DISCOUNT_LAB_FAIL";

  
export const UPDATE_DISCOUNT_LAB = "UPDATE_DISCOUNT_LAB";
export const UPDATE_DISCOUNT_LAB_SUCCESS =
  "UPDATE_DISCOUNT_LAB_SUCCESS";
export const UPDATE_DISCOUNT_LAB_FAIL =
  "UPDATE_DISCOUNT_LAB_FAIL";

  export const UPDATE_DISCOUNT_ALL_LAB = "UPDATE_DISCOUNT_ALL_LAB";
export const UPDATE_DISCOUNT_ALL_LAB_SUCCESS =
  "UPDATE_DISCOUNT_ALL_LAB_SUCCESS";
export const UPDATE_DISCOUNT_ALL_LAB_FAIL =
  "UPDATE_DISCOUNT_ALL_LAB_FAIL";
