
export const GET_BANKS = "get_banks";
export const GET_BANKS_SUCCESS = "get_banks_success";
export const GET_BANKS_FAIL = "get_banks_fail";

export const GET_BANK_TRANSFER =
  "GET_BANK_TRANSFER";
export const GET_BANK_TRANSFER_SUCCESS =
  "GET_BANK_TRANSFER_SUCCESS";
export const GET_BANK_TRANSFER_FAIL =
  "GET_BANK_TRANSFER_FAIL";

export const ADD_NEW_BANK_TRANSFER = "ADD_NEW_BANK_TRANSFER";
export const ADD_BANK_TRANSFER_SUCCESS =
  "ADD_BANK_TRANSFER_SUCCESS";
export const ADD_BANK_TRANSFER_FAIL = "ADD_BANK_TRANSFER_FAIL";

export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const GET_BANK_ACCOUNTS_SUCCESS =
  "GET_BANK_ACCOUNTS_SUCCESS";
export const GET_BANK_ACCOUNTS_FAIL = "GET_BANK_ACCOUNTS_FAIL";

export const UPDATE_BANK_TRANSFER = "UPDATE_BANK_TRANSFER";
export const UPDATE_BANK_TRANSFER_SUCCESS =
  "UPDATE_BANK_TRANSFER_SUCCESS";
export const UPDATE_BANK_TRANSFER_FAIL =
  "UPDATE_BANK_TRANSFER_FAIL";
