/* PATHOLOGISTS */
export const GET_PATHOLOGISTS = "GET_PATHOLOGISTS";
export const GET_PATHOLOGISTS_SUCCESS = "GET_PATHOLOGISTS_SUCCESS";
export const GET_PATHOLOGISTS_FAIL = "GET_PATHOLOGISTS_FAIL";

export const ADD_NEW_PATHOLOGIST = "ADD_NEW_PATHOLOGIST";
export const ADD_PATHOLOGIST_SUCCESS = "ADD_PATHOLOGIST_SUCCESS";
export const ADD_PATHOLOGIST_FAIL = "ADD_PATHOLOGIST_FAIL";

export const UPDATE_PATHOLOGIST = "UPDATE_PATHOLOGIST";
export const UPDATE_PATHOLOGIST_SUCCESS = "UPDATE_PATHOLOGIST_SUCCESS";
export const UPDATE_PATHOLOGIST_FAIL = "UPDATE_PATHOLOGIST_FAIL";

export const DELETE_PATHOLOGIST = "DELETE_PATHOLOGIST";
export const DELETE_PATHOLOGIST_SUCCESS = "DELETE_PATHOLOGIST_SUCCESS";
export const DELETE_PATHOLOGIST_FAIL = "DELETE_PATHOLOGIST_FAIL";
