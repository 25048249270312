export const ADD_CORPORATE_INFORMATION = "add_corporate_information";
export const ADD_CORPORATE_INFORMATION_SUCCESSFUL =
  "add_corporate_information_successfull";
export const ADD_CORPORATE_INFORMATION_FAILED =
  "add_corporate_information_failed";


// Territories
export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";
