/* SAMPLE COLLECTORS */
export const GET_QUALITY_CERTIFICATES = "GET_QUALITY_CERTIFICATES";
export const GET_QUALITY_CERTIFICATES_SUCCESS =
  "GET_QUALITY_CERTIFICATES_SUCCESS";
export const GET_QUALITY_CERTIFICATES_FAIL = "GET_QUALITY_CERTIFICATES_FAIL";

export const ADD_NEW_QUALITY_CERTIFICATE = "ADD_NEW_QUALITY_CERTIFICATE";
export const ADD_QUALITY_CERTIFICATE_SUCCESS =
  "ADD_QUALITY_CERTIFICATE_SUCCESS";
export const ADD_QUALITY_CERTIFICATE_FAIL = "ADD_QUALITY_CERTIFICATE_FAIL";

export const UPDATE_QUALITY_CERTIFICATE = "UPDATE_QUALITY_CERTIFICATE";
export const UPDATE_QUALITY_CERTIFICATE_SUCCESS =
  "UPDATE_QUALITY_CERTIFICATE_SUCCESS";
export const UPDATE_QUALITY_CERTIFICATE_FAIL =
  "UPDATE_QUALITY_CERTIFICATE_FAIL";

export const DELETE_QUALITY_CERTIFICATE = "DELETE_QUALITY_CERTIFICATE";
export const DELETE_QUALITY_CERTIFICATE_SUCCESS =
  "DELETE_QUALITY_CERTIFICATE_SUCCESS";
export const DELETE_QUALITY_CERTIFICATE_FAIL =
  "DELETE_QUALITY_CERTIFICATE_FAIL";

export const ADD_NEW_COLLECTIONPOINT_QUALITY = "ADD_NEW_COLLECTIONPOINT_QUALITY";
export const ADD_COLLECTIONPOINT_QUALITY_SUCCESS = "ADD_COLLECTIONPOINT_QUALITY_SUCCESS";
export const ADD_COLLECTIONPOINT_QUALITY_FAIL = "ADD_COLLECTIONPOINT_QUALITY_FAIL";

export const GET_LAB_PROFILE = "GET_LAB_PROFILE";
export const GET_LAB_PROFILE_SUCCESS = "GET_LAB_PROFILE_SUCCESS";
export const GET_LAB_PROFILE_FAIL = "GET_LAB_PROFILE_FAIL";
