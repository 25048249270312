/* finance */
export const GET_ACTIVITY_LOG_FINANCE = "GET_ACTIVITY_LOG_FINANCE";
export const GET_ACTIVITY_LOG_FINANCE_SUCCESS = "GET_ACTIVITY_LOG_FINANCE_SUCCESS";
export const GET_ACTIVITY_LOG_FINANCE_FAIL = "GET_ACTIVITY_LOG_FINANCE_FAIL";

// Corporate Commit

export const GET_CORPORATE_COMMIT = "GET_CORPORATE_COMMIT";
export const GET_CORPORATE_COMMIT_SUCCESS = "GET_CORPORATE_COMMIT_SUCCESS";
export const GET_CORPORATE_COMMIT_FAIL = "GET_CORPORATE_COMMIT_FAIL";
