/* TEST APPOINTMENTtS */
export const GET_TEST_APPOINTMENTS_PENDING_LIST = "GET_TEST_APPOINTMENTS_PENDING_LIST";
export const GET_TEST_APPOINTMENTS_PENDING_LIST_SUCCESS =
  "GET_TEST_APPOINTMENTS_PENDING_LIST_SUCCESS";
export const GET_TEST_APPOINTMENTS_PENDING_LIST_FAIL = "GET_TEST_APPOINTMENTS_PENDING_LIST_FAIL";

export const GET_TEST_APPOINTMENTS_IN_PROCESS_LIST = "GET_TEST_APPOINTMENTS_IN_PROCESS_LIST";
export const GET_TEST_APPOINTMENTS_IN_PROCESS_LIST_SUCCESS =
  "GET_TEST_APPOINTMENTS_IN_PROCESS_LIST_SUCCESS";
export const GET_TEST_APPOINTMENTS_IN_PROCESS_LIST_FAIL = "GET_TEST_APPOINTMENTS_IN_PROCESS_LIST_FAIL";

export const GET_TEST_APPOINTMENTS_COMPLETED_LIST = "GET_TEST_APPOINTMENTS_COMPLETED_LIST";
export const GET_TEST_APPOINTMENTS_COMPLETED_LIST_SUCCESS =
  "GET_TEST_APPOINTMENTS_COMPLETED_LIST_SUCCESS";
export const GET_TEST_APPOINTMENTS_COMPLETED_LIST_FAIL = "GET_TEST_APPOINTMENTS_COMPLETED_LIST_FAIL";

export const UPDATE_TEST_APPOINTMENT = "UPDATE_TEST_APPOINTMENT";
export const UPDATE_TEST_APPOINTMENT_SUCCESS =
  "UPDATE_TEST_APPOINTMENT_SUCCESS";
export const UPDATE_TEST_APPOINTMENT_FAIL =
  "UPDATE_TEST_APPOINTMENT_FAIL";

export const ADD_NEW_COLLECTIONPOINT_TESTAPPOINTMENT = "ADD_NEW_COLLECTIONPOINT_TESTAPPOINTMENT";
export const ADD_COLLECTIONPOINT_TESTAPPOINTMENT_SUCCESS = "ADD_COLLECTIONPOINT_TESTAPPOINTMENT_SUCCESS";
export const ADD_COLLECTIONPOINT_TESTAPPOINTMENT_FAIL = "ADD_COLLECTIONPOINT_TESTAPPOINTMENT_FAIL";

export const GET_LAB_PROFILE = "GET_LAB_PROFILE";
export const GET_LAB_PROFILE_SUCCESS = "GET_LAB_PROFILE_SUCCESS";
export const GET_LAB_PROFILE_FAIL = "GET_LAB_PROFILE_FAIL";