/* SAMPLE COLLECTORS */
export const GET_NOTES = "GET_NOTES";
export const GET_NOTES_SUCCESS =
  "GET_NOTES_SUCCESS";
export const GET_NOTES_FAIL = "GET_NOTES_FAIL";

export const ADD_NEW_NOTE = "ADD_NEW_NOTE";
export const ADD_NOTE_SUCCESS =
  "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL";

/* CSR AND CSR ADMIN */

export const GET_NOTES_COMPLAINT = "GET_NOTES_COMPLAINT";
export const GET_NOTES_COMPLAINT_SUCCESS =
  "GET_NOTES_COMPLAINT_SUCCESS";
export const GET_NOTES_COMPLAINT_FAIL = "GET_NOTES_COMPLAINT_FAIL";

export const ADD_NEW_NOTE_COMPLAINT = "ADD_NEW_NOTE_COMPLAINT";
export const ADD_NOTE_COMPLAINT_SUCCESS =
  "ADD_NOTE_COMPLAINT_SUCCESS";
export const ADD_NOTE_COMPLAINT_FAIL = "ADD_NOTE_COMPLAINT_FAIL";

