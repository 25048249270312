/*  PATIENT TEST APPOINTMENTtS */

export const GET_TESTS_LIST =
  "GET_TESTS_LIST";
export const GET_TESTS_LIST_SUCCESS =
  "GET_TESTS_LIST_SUCCESS";
export const GET_TESTS_LIST_FAIL =
  "GET_TESTS_LIST_FAIL";

export const GET_CORPORATE_TESTS_LIST =
  "GET_CORPORATE_TESTS_LIST";
export const GET_CORPORATE_TESTS_LIST_SUCCESS =
  "GET_CORPORATE_TESTS_LIST_SUCCESS";
export const GET_CORPORATE_TESTS_LIST_FAIL =
  "GET_CORPORATE_TESTS_LIST_FAIL";
