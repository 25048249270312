/*  PATIENT TEST APPOINTMENTtS */

export const GET_ONLY_MEDICAL_LIST =
  "GET_ONLY_MEDICAL_LIST";
export const GET_ONLY_MEDICAL_LIST_SUCCESS =
  "GET_ONLY_MEDICAL_LIST_SUCCESS";
export const GET_ONLY_MEDICAL_LIST_FAIL =
  "GET_ONLY_MEDICAL_LIST_FAIL";

  /*  PATIENT TEST APPOINTMENTtS */

export const GET_TERRITORIES_LIST =
"GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
"GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
"GET_TERRITORIES_LIST_FAIL";
