/* CORPORATE PROFILE */
export const GET_CORPORATE_PROFILE = "GET_CORPORATE_PROFILE";
export const GET_CORPORATE_PROFILE_SUCCESS = "GET_CORPORATE_PROFILE_SUCCESS";
export const GET_CORPORATE_PROFILE_FAIL = "GET_CORPORATE_PROFILE_FAIL";


export const UPDATE_CORPORATE_PROFILE = "UPDATE_CORPORATE_PROFILE";
export const UPDATE_CORPORATE_PROFILE_SUCCESS =
  "UPDATE_CORPORATE_PROFILE_SUCCESS";
export const UPDATE_CORPORATE_PROFILE_FAIL = "UPDATE_CORPORATE_PROFILE_FAIL";
