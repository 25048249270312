/* PRODUCTS */
export const GET_NEARBY_TESTS = "GET_NEARBY_TESTS";
export const GET_NEARBY_TESTS_SUCCESS = "GET_NEARBY_TESTS_SUCCESS";
export const GET_NEARBY_TESTS_FAIL = "GET_NEARBY_TESTS_FAIL";

export const GET_NEARBY_TESTS_DISCOUNTEDLH = "GET_NEARBY_TESTS_DISCOUNTEDLH";
export const GET_NEARBY_TESTS_DISCOUNTEDLH_SUCCESS = "GET_NEARBY_TESTS_DISCOUNTEDLH_SUCCESS";
export const GET_NEARBY_TESTS_DISCOUNTEDLH_FAIL = "GET_NEARBY_TESTS_DISCOUNTEDLH_FAIL";

export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";