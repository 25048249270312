/* BANKACCOUNTS */
export const GET_BANKACCOUNTS = "GET_BANKACCOUNTS";
export const GET_BANKACCOUNTS_SUCCESS = "GET_BANKACCOUNTS_SUCCESS";
export const GET_BANKACCOUNTS_FAIL = "GET_BANKACCOUNTS_FAIL";


export const UPDATE_BANKACCOUNT = "UPDATE_BANKACCOUNT";
export const UPDATE_BANKACCOUNT_SUCCESS = "UPDATE_BANKACCOUNT_SUCCESS";
export const UPDATE_BANKACCOUNT_FAIL = "UPDATE_BANKACCOUNT_FAIL";

// export const DELETE_BANKACCOUNT = "DELETE_BANKACCOUNT";
// export const DELETE_BANKACCOUNT_SUCCESS = "DELETE_BANKACCOUNT_SUCCESS";
// export const DELETE_BANKACCOUNT_FAIL = "DELETE_BANKACCOUNT_FAIL";
