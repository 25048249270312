export const GET_LABS = "get_labs";
export const GET_LABS_SUCCESS = "get_labs_success";
export const GET_LABS_FAIL = "get_labs_fail";

export const GET_DONORS = "get_donors";
export const GET_DONORS_SUCCESS = "get_donors_success";
export const GET_DONORS_FAIL = "get_donors_fail";

export const GET_STAFF_PROFILE = "GET_STAFF_PROFILE";
export const GET_STAFF_PROFILE_SUCCESS = "GET_STAFF_PROFILE_SUCCESS";
export const GET_STAFF_PROFILE_FAIL = "GET_STAFF_PROFILE_FAIL";


/*  SHARES */
export const GET_ACCEPTED_LAB_ADVERTISEMENTS = "GET_ACCEPTED_LAB_ADVERTISEMENTS";
export const GET_ACCEPTED_LAB_ADVERTISEMENTS_SUCCESS =
  "GET_ACCEPTED_LAB_ADVERTISEMENTS_SUCCESS";
export const GET_ACCEPTED_LAB_ADVERTISEMENTS_FAIL = "GET_ACCEPTED_LAB_ADVERTISEMENTS_FAIL";

export const GET_IN_PAYMENT =
  "GET_IN_PAYMENT";
export const GET_IN_PAYMENT_SUCCESS =
  "GET_IN_PAYMENT_SUCCESS";
export const GET_IN_PAYMENT_FAIL =
  "GET_IN_PAYMENT_FAIL";

export const ADD_NEW_IN_PAYMENT = "ADD_NEW_IN_PAYMENT";
export const ADD_IN_PAYMENT_SUCCESS =
  "ADD_IN_PAYMENT_SUCCESS";
export const ADD_IN_PAYMENT_FAIL = "ADD_IN_PAYMENT_FAIL";
