/*  PATIENT TEST APPOINTMENTtS */

export const GET_AUDITOR_CENTRAL_TERRITORY_LIST =
  "GET_AUDITOR_CENTRAL_TERRITORY_LIST";
export const GET_AUDITOR_CENTRAL_TERRITORY_LIST_SUCCESS =
  "GET_AUDITOR_CENTRAL_TERRITORY_LIST_SUCCESS";
export const GET_AUDITOR_CENTRAL_TERRITORY_LIST_FAIL =
  "GET_AUDITOR_CENTRAL_TERRITORY_LIST_FAIL";

// AUDITOR SOUTH
export const GET_AUDITOR_SOUTH_TERRITORY_LIST =
  "GET_AUDITOR_SOUTH_TERRITORY_LIST";
export const GET_AUDITOR_SOUTH_TERRITORY_LIST_SUCCESS =
  "GET_AUDITOR_SOUTH_TERRITORY_LIST_SUCCESS";
export const GET_AUDITOR_SOUTH_TERRITORY_LIST_FAIL =
  "GET_AUDITOR_SOUTH_TERRITORY_LIST_FAIL";

// AUDITOR North
export const GET_AUDITOR_NORTH_TERRITORY_LIST =
  "GET_AUDITOR_NORTH_TERRITORY_LIST";
export const GET_AUDITOR_NORTH_TERRITORY_LIST_SUCCESS =
  "GET_AUDITOR_NORTH_TERRITORY_LIST_SUCCESS";
export const GET_AUDITOR_NORTH_TERRITORY_LIST_FAIL =
  "GET_AUDITOR_NORTH_TERRITORY_LIST_FAIL";
