export const ADD_B2BCLIENT_INFORMATION = "add_b2bclient_information"
export const ADD_B2BCLIENT_INFORMATION_SUCCESSFUL = "add_b2bclient_information_successfull"
export const ADD_B2BCLIENT_INFORMATION_FAILED = "add_b2bclient_information_failed"

// Territories
export const GET_TERRITORIES_LIST =
  "GET_TERRITORIES_LIST";
export const GET_TERRITORIES_LIST_SUCCESS =
  "GET_TERRITORIES_LIST_SUCCESS";
export const GET_TERRITORIES_LIST_FAIL =
  "GET_TERRITORIES_LIST_FAIL";
