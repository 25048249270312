export const GET_LABS_MOF = "GET_LABS_MOF";
export const GET_LABS_MOF_SUCCESS = "GET_LABS_MOF_success";
export const GET_LABS_MOF_FAIL = "GET_LABS_MOF_fail";

export const GET_LABS_C = "GET_LABS_C";
export const GET_LABS_C_SUCCESS = "GET_LABS_C_success";
export const GET_LABS_C_FAIL = "GET_LABS_C_fail";

export const GET_LIST_DONATIONAPPOINTMENT = "GET_LIST_DONATIONAPPOINTMENT";
export const GET_LIST_DONATIONAPPOINTMENT_SUCCESS = "GET_LIST_DONATIONAPPOINTMENT_success";
export const GET_LIST_DONATIONAPPOINTMENT_FAIL = "GET_LIST_DONATIONAPPOINTMENT_fail";

export const GET_LIST_CLABS = "GET_LIST_CLABS";
export const GET_LIST_CLABS_SUCCESS = "GET_LIST_CLABS_success";
export const GET_LIST_CLABS_FAIL = "GET_LIST_CLABS_fail";

export const GET_LIST_INVOICE = "GET_LIST_INVOICE";
export const GET_LIST_INVOICE_SUCCESS = "GET_LIST_INVOICE_SUCCESS";
export const GET_LIST_INVOICE_FAIL = "GET_LIST_INVOICE_FAIL";

export const GET_BANKS = "get_banks";
export const GET_BANKS_SUCCESS = "get_banks_success";
export const GET_BANKS_FAIL = "get_banks_fail";

export const GET_STAFF_PROFILE = "GET_STAFF_PROFILE";
export const GET_STAFF_PROFILE_SUCCESS = "GET_STAFF_PROFILE_SUCCESS";
export const GET_STAFF_PROFILE_FAIL = "GET_STAFF_PROFILE_FAIL";

export const GET_CORPORATE_PROFILE = "GET_CORPORATE_PROFILE";
export const GET_CORPORATE_PROFILE_SUCCESS = "GET_CORPORATE_PROFILE_SUCCESS";
export const GET_CORPORATE_PROFILE_FAIL = "GET_CORPORATE_PROFILE_FAIL";

export const GET_OUT_PAYMENT =
  "GET_OUT_PAYMENT";
export const GET_OUT_PAYMENT_SUCCESS =
  "GET_OUT_PAYMENT_SUCCESS";
export const GET_OUT_PAYMENT_FAIL =
  "GET_OUT_PAYMENT_FAIL";

export const ADD_NEW_OUT_PAYMENT = "ADD_NEW_OUT_PAYMENT";
export const ADD_OUT_PAYMENT_SUCCESS =
  "ADD_OUT_PAYMENT_SUCCESS";
export const ADD_OUT_PAYMENT_FAIL = "ADD_OUT_PAYMENT_FAIL";

export const ADD_NEW_CORPORATE_PAYMENT = "ADD_NEW_CORPORATE_PAYMENT";
export const ADD_CORPORATE_PAYMENT_SUCCESS =
  "ADD_CORPORATE_PAYMENT_SUCCESS";
export const ADD_CORPORATE_PAYMENT_FAIL = "ADD_CORPORATE_PAYMENT_FAIL";

export const ADD_NEW_INVOICE_ADJUSTMENT = "ADD_NEW_INVOICE_ADJUSTMENT";
export const ADD_INVOICE_ADJUSTMENT_SUCCESS =
  "ADD_INVOICE_ADJUSTMENT_SUCCESS";
export const ADD_INVOICE_ADJUSTMENT_FAIL = "ADD_INVOICE_ADJUSTMENT_FAIL";

export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const GET_BANK_ACCOUNTS_SUCCESS =
  "GET_BANK_ACCOUNTS_SUCCESS";
export const GET_BANK_ACCOUNTS_FAIL = "GET_BANK_ACCOUNTS_FAIL";

export const GET_B2B_CLIENTS =
  "GET_B2B_CLIENTS";
export const GET_B2B_CLIENTS_SUCCESS =
  "GET_B2B_CLIENTS_SUCCESS";
export const GET_B2B_CLIENTS_FAIL =
  "GET_B2B_CLIENTS_FAIL";