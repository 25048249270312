export const GET_PENDING_CORPORATE = "GET_PENDING_CORPORATE";
export const GET_PENDING_CORPORATE_SUCCESS = "GET_PENDING_CORPORATE_SUCCESS";
export const GET_PENDING_CORPORATE_FAIL = "GET_PENDING_CORPORATE_FAIL";

export const GET_APPROVED_CORPORATE = "GET_APPROVED_CORPORATE";
export const GET_APPROVED_CORPORATE_SUCCESS = "GET_APPROVED_CORPORATE_SUCCESS";
export const GET_APPROVED_CORPORATE_FAIL = "GET_APPROVED_CORPORATE_FAIL";

export const GET_UNAPPROVED_CORPORATE = "GET_UNAPPROVED_CORPORATE";
export const GET_UNAPPROVED_CORPORATE_SUCCESS = "GET_UNAPPROVED_CORPORATE_SUCCESS";
export const GET_UNAPPROVED_CORPORATE_FAIL = "GET_UNAPPROVED_CORPORATE_FAIL";

export const APPROVE_UNAPPROVE_CORPORATE = "APPROVE_UNAPPROVE_CORPORATE";
export const APPROVE_UNAPPROVE_CORPORATE_SUCCESS = "APPROVE_UNAPPROVE_CORPORATE_SUCCESS";
export const APPROVE_UNAPPROVE_CORPORATE_FAIL = "APPROVE_UNAPPROVE_CORPORATE_FAIL";

// -------------------- LAB ACTION TYPES --------------------
export const GET_PENDING_LABS = "GET_PENDING_LABS";
export const GET_PENDING_LABS_SUCCESS = "GET_PENDING_LABS_SUCCESS";
export const GET_PENDING_LABS_FAIL = "GET_PENDING_LABS_FAIL";

export const GET_APPROVED_LABS = "GET_APPROVED_LABS";
export const GET_APPROVED_LABS_SUCCESS = "GET_APPROVED_LABS_SUCCESS";
export const GET_APPROVED_LABS_FAIL = "GET_APPROVED_LABS_FAIL";

export const GET_UNAPPROVED_LABS = "GET_UNAPPROVED_LABS";
export const GET_UNAPPROVED_LABS_SUCCESS = "GET_UNAPPROVED_LABS_SUCCESS";
export const GET_UNAPPROVED_LABS_FAIL = "GET_UNAPPROVED_LABS_FAIL";

export const APPROVE_UNAPPROVE_LAB = "APPROVE_UNAPPROVE_LAB";
export const APPROVE_UNAPPROVE_LAB_SUCCESS = "APPROVE_UNAPPROVE_LAB_SUCCESS";
export const APPROVE_UNAPPROVE_LAB_FAIL = "APPROVE_UNAPPROVE_LAB_FAIL";

// -------------------- B2B ACTION TYPES --------------------
export const GET_PENDING_B2B_CLIENTS = "GET_PENDING_B2B_CLIENTS";
export const GET_PENDING_B2B_CLIENTS_SUCCESS =
  "GET_PENDING_B2B_CLIENTS_SUCCESS";
export const GET_PENDING_B2B_CLIENTS_FAIL = "GET_PENDING_B2B_CLIENTS_FAIL";

export const GET_APPROVED_B2B_CLIENTS = "GET_APPROVED_B2B_CLIENTS";
export const GET_APPROVED_B2B_CLIENTS_SUCCESS =
  "GET_APPROVED_B2B_CLIENTS_SUCCESS";
export const GET_APPROVED_B2B_CLIENTS_FAIL = "GET_APPROVED_B2B_CLIENTS_FAIL";

export const GET_UNAPPROVED_B2B_CLIENTS = "GET_UNAPPROVED_B2B_CLIENTS";
export const GET_UNAPPROVED_B2B_CLIENTS_SUCCESS =
  "GET_UNAPPROVED_B2B_CLIENTS_SUCCESS";
export const GET_UNAPPROVED_B2B_CLIENTS_FAIL =
  "GET_UNAPPROVED_B2B_CLIENTS_FAIL";

export const APPROVE_UNAPPROVE_B2B_CLIENT = "APPROVE_UNAPPROVE_B2B_CLIENT";
export const APPROVE_UNAPPROVE_B2B_CLIENT_SUCCESS =
  "APPROVE_UNAPPROVE_B2B_CLIENT_SUCCESS";
export const APPROVE_UNAPPROVE_B2B_CLIENT_FAIL =
  "APPROVE_UNAPPROVE_B2B_CLIENT_FAIL";

// -------------------- DONOR ACTION TYPES --------------------
export const GET_PENDING_DONORS = "GET_PENDING_DONORS";
export const GET_PENDING_DONORS_SUCCESS = "GET_PENDING_DONORS_SUCCESS";
export const GET_PENDING_DONORS_FAIL = "GET_PENDING_DONORS_FAIL";

export const GET_APPROVED_DONORS = "GET_APPROVED_DONORS";
export const GET_APPROVED_DONORS_SUCCESS = "GET_APPROVED_DONORS_SUCCESS";
export const GET_APPROVED_DONORS_FAIL = "GET_APPROVED_DONORS_FAIL";

export const GET_UNAPPROVED_DONORS = "GET_UNAPPROVED_DONORS";
export const GET_UNAPPROVED_DONORS_SUCCESS = "GET_UNAPPROVED_DONORS_SUCCESS";
export const GET_UNAPPROVED_DONORS_FAIL = "GET_UNAPPROVED_DONORS_FAIL";

export const APPROVE_UNAPPROVE_DONOR = "APPROVE_UNAPPROVE_DONOR";
export const APPROVE_UNAPPROVE_DONOR_SUCCESS =
  "APPROVE_UNAPPROVE_DONOR_SUCCESS";
export const APPROVE_UNAPPROVE_DONOR_FAIL = "APPROVE_UNAPPROVE_DONOR_FAIL";
