export const DISTRICTS = [
  {
    options: [
      { label: "Abbottabad", value: "Abbottabad" },
      { label: "Attock", value: "Attock" },
      { label: "Awaran", value: "Awaran" },
      { label: "Badin", value: "Badin" },
      { label: "Bajur", value: "Bajur" },
      { label: "Bannu", value: "Bannu" },
      { label: "Batagram", value: "Batagram" },
      { label: "Bunair", value: "Bunair" },
      { label: "Bahawalnagar", value: "Bahawalnagar" },
      { label: "Bahawalpur", value: "Bahawalpur" },
      { label: "Bhakkar", value: "Bhakkar" },
      { label: "Barkhan", value: "Barkhan" },
      { label: "Chakwal", value: "Chakwal" },
      { label: "Chagai", value: "Chagai" },
      { label: "Charsada", value: "Charsada" },
      { label: "Chitral", value: "Chitral" },
      { label: "Chiniot", value: "Chiniot" },
      { label: "Dadu", value: "Dadu" },
      { label: "Dera Bugti", value: "Dera Bugti" },
      { label: "Dera Ghazi Khan", value: "Dera Ghazi Khan" },
      { label: "Dera Ismail Khan", value: "Dera Ismail Khan" },
      { label: "Duki", value: "Duki" },
      { label: "Faisalabad", value: "Faisalabad" },
      { label: "Ghotki", value: "Ghotki" },
      { label: "Gujranwala", value: "Gujranwala" },
      { label: "Gujrat", value: "Gujrat" },
      { label: "Gwadar", value: "Gwadar" },
      { label: "Hangu", value: "Hangu" },
      { label: "Hafizabad", value: "Haripur" },
      { label: "Haripur", value: "Haripur" },
      { label: "Harnai", value: "Harnai" },
      { label: "Hyderabad", value: "Hyderabad" },
      { label: "Islamabad", value: "Islamabad" },
      { label: "Jacobabad", value: "Jacobabad" },
      { label: "Jaffarabad", value: "Jaffarabad" },
      { label: "Jamshoro", value: "Jamshoro" },
      { label: "Jhal Magsi", value: "Jhal Magsi" },
      { label: "Jhang", value: "Jhang" },
      { label: "Jhelum", value: "Jhelum" },
      { label: "Kachhi/Bolan", value: "Kachhi/Bolan" },
      { label: "Kalat", value: "Kalat" },
      { label: "Karachi", value: "Karachi" },
      { label: "Karak", value: "Karak" },
      { label: "Kasur", value: "Kasur" },
      { label: "Kashmore", value: "Kashmore" },
      { label: "Kech/Turbat", value: "Kech/Turbat" },
      { label: "Khairpur", value: "Khairpur" },
      { label: "Kharan", value: "Kharan" },
      { label: "Khushab", value: "Khushab" },
      { label: "Khuzdar", value: "Khuzdar" },
      { label: "Khyber", value: "Khyber" },
      { label: "Kohat", value: "Kohat" },
      { label: "Kohistan", value: "Kohistan" },
      { label: "Kohlu", value: "Kohlu" },
      { label: "Korangi", value: "Korangi" },
      { label: "Kurram", value: "Kurram" },
      { label: "Lahore", value: "Lahore" },
      { label: "Lakki Marwat", value: "Lakki Marwat" },
      { label: "Larkana", value: "Larkana" },
      { label: "Lasbela", value: "Lasbela" },
      { label: "Layyah", value: "Layyah" },
      { label: "Lodhran", value: "Lodhran" },
      { label: "Loralai", value: "Loralai" },
      { label: "Lower Dir", value: "Lower Dir" },
      { label: "Malakand", value: "Malakand" },
      { label: "Mansehra", value: "Mansehra" },
      { label: "Mardan", value: "Mardan" },
      { label: "Mastung", value: "Mastung" },
      { label: "Matiari", value: "Matiari" },
      { label: "Mandi Bahauddin", value: "Mandi Bahauddin" },
      { label: "Mianwali", value: "Mianwali" },
      { label: "Mirpur Khas", value: "Mirpur Khas" },
      { label: "Mohmand", value: "Mohmand" },
      { label: "Musa Khel", value: "Musa Khel" },
      { label: "Muzaffargarh", value: "Muzaffargarh" },
      { label: "Nasirabad/Tamboo", value: "Nasirabad/Tamboo" },
      { label: "Narowal", value: "Narowal" },
      { label: "Nankana Sahib", value: "Nankana Sahib" },
      { label: "Nowshera", value: "Nowshera" },
      { label: "Nowshero Feroze ", value: "Nowshero Feroze " },
      { label: "Nushki", value: "Nushki" },
      { label: "Orakzai", value: "Orakzai" },
      { label: "Okara", value: "Okara" },
      { label: "Pakpattan", value: "Pakpattan" },
      { label: "Panjgoor", value: "Panjgoor" },
      { label: "Peshawar", value: "Peshawar" },
      { label: "Pishin", value: "Pishin" },
      { label: "Qilla Abdullah", value: "Qilla Abdullah" },
      { label: "Qilla Saifullah", value: "Qilla Saifullah" },
      { label: "Quetta", value: "Quetta" },
      { label: "Rajanpur", value: "Rajanpur" },
      { label: "Rahim Yar Khan", value: "Rahim Yar Khan" },
      { label: "Rawalpindi", value: "Rawalpindi" },
      { label: "Sahiwal", value: "Sahiwal" },
      { label: "Sanghar ", value: "Sanghar " },
      { label: "Sargodha", value: "Sargodha" },
      { label: "Shahdadkot", value: "Shahdadkot" },
      { label: "Shaheed Banazir Abad", value: "Shaheed Banazir Abad" },
      { label: "Shaheed Sikandar Abad", value: "Shaheed Sikandar Abad" },
      { label: "Shangla", value: "Shangla" },
      { label: "Sheikhupura", value: "Sheikhupura" },
      { label: "Sherani", value: "Sherani" },
      { label: "Shikarpur", value: "Shikarpur" },
      { label: "Sialkot", value: "Sialkot" },
      { label: "Sibbi", value: "Sibbi" },
      { label: "Sohbatpur", value: "Sohbatpur" },
      { label: "Sujawal", value: "Sujawal" },
      { label: "Sukkur", value: "Sukkur" },
      { label: "Swabi", value: "Swabi" },
      { label: "Swat", value: "Swat" },
      { label: "Tando Allah Yar", value: "Tando Allah Yar" },
      { label: "Tando Muhammad Khan", value: "Tando Muhammad Khan" },
      { label: "Tank", value: "Tank" },
      { label: "Tharparkar", value: "Tharparkar" },
      { label: "Thatta", value: "Thatta" },
      { label: "Toba Tek Singh", value: "Toba Tek Singh" },
      { label: "Tor Garh", value: "Tor Garh" },
      { label: "Vehari", value: "Vehari" },
      { label: "Umer Kot", value: "Umer Kot" },
      { label: "Upper Dir", value: "Upper Dir" },
      { label: "Washuk", value: "Washuk" },
      { label: "Waziristan", value: "Waziristan" },
      { label: "Zhob", value: "Zhob" },
      { label: "Ziarat", value: "Ziarat" },
      { label: "Other", value: "Other" },
    ],
  },
];

export const CITIES = [
  {
    options: [
      { label: "Abbottabad", value: "Abbottabad" },
      { label: "Ahmadpur East", value: "Ahmadpur East" },
      { label: "Ahmed Nager Chatha", value: "Ahmed Nager Chatha" },
      { label: "Ali Khan Abad", value: "Ali Khan Abad" },
      { label: "Alipur", value: "Alipur" },
      { label: "Arifwala", value: "Arifwala" },
      { label: "Attock", value: "Attock" },
      { label: "Awaran", value: "Awaran" },
      { label: "Badin", value: "Badin" },
      { label: "Bhera", value: "Bhera" },
      { label: "Bhalwal", value: "Bhalwal" },
      { label: "Bahawalnagar", value: "Bahawalnagar" },
      { label: "Bahawalpur", value: "Bahawalpur" },
      { label: "Bhakkar", value: "Bhakkar" },
      { label: "Bolhari", value: "Bolhari" },
      { label: "Burewala", value: "Burewala" },
      { label: "Chakwal", value: "Chakwal" },
      { label: "Chaman", value: "Chaman" },
      { label: "Charsada", value: "Charsada" },
      { label: "Chenab Nagar", value: "Chenab Nagar" },
      { label: "Chillianwala", value: "Chillianwala" },
      { label: "Choa Saidanshah", value: "Choa Saidanshah" },
      { label: "Chichawatni", value: "Chichawatni" },
      { label: "Chiniot", value: "Chiniot" },
      { label: "Chishtian", value: "Chishtian" },
      { label: "Chunian", value: "Chunian" },
      { label: "Dadu", value: "Dajkot" },
      { label: "Dajkot", value: "Dajkot" },
      { label: "Daska", value: "Daska" },
      { label: "Darya Khan", value: "Darya Khan" },
      { label: "Dera Ghazi Khan", value: "Dera Ghazi Khan" },
      { label: "Dera Ismail Khan", value: "Dera Ismail Khan" },
      { label: "Dhaular", value: "Dhaular" },
      { label: "Dina", value: "Dina" },
      { label: "Dinga", value: "Dinga" },
      { label: "Dir", value: "Dir" },
      { label: "Dhudial Chakwal", value: "Dhudial Chakwal" },
      { label: "Dipalpur", value: "Dipalpur" },
      { label: "Faisalabad", value: "Faisalabad" },
      { label: "Fateh Jang", value: "Fateh Jang" },
      { label: "Ferozewala", value: "Ferozewala" },
      { label: "Ghakhar Mandi", value: "Ghakhar Mandi" },
      { label: "Ghotki", value: "Ghotki" },
      { label: "Gojra", value: "Gojra" },
      { label: "Gujranwala", value: "Gujranwala" },
      { label: "Gujrat", value: "Gujrat" },
      { label: "Gujar Khan", value: "Gujar Khan" },
      { label: "Harappa", value: "Harappa" },
      { label: "Hafizabad", value: "Hafizabad" },
      { label: "Haroonabad", value: "Haroonabad" },
      { label: "Hasilpur", value: "Hasilpur" },
      { label: "Haveli Lakha", value: "Haveli Lakha" },
      { label: "Hub", value: "Hub" },
      { label: "Hyderabad", value: "Hyderabad" },
      { label: "Islamabad", value: "Islamabad" },
      { label: "Jacobabad", value: "Jacobabad" },
      { label: "Jampur", value: "Jampur" },
      { label: "Jaranwala", value: "Jaranwala" },
      { label: "Jatoi", value: "Jatoi" },
      { label: "Jhang", value: "Jhang" },
      { label: "Jhelum", value: "Jhelum" },
      { label: "Kabal", value: "Kabal" },
      { label: "Kallar Syedan", value: "Kallar Syedan" },
      { label: "Kalabagh", value: "Kalabagh" },
      { label: "Kamalia", value: "Kamalia" },
      { label: "Kamalia", value: "Kamalia" },
      { label: "Kambar Ali Khan", value: "Kambar Ali Khan" },
      { label: "Kamoke", value: "Kamoke" },
      { label: "Kandhkot", value: "Kandhkot" },
      { label: "Karachi", value: "Karachi" },
      { label: "Karor Lal Esan", value: "Karor Lal Esan" },
      { label: "Kasur", value: "Kasur" },
      { label: "Khairpur", value: "Khairpur" },
      { label: "Khanewal", value: "Khanewal" },
      { label: "Khanpur", value: "Khanpur" },
      { label: "Khanqah Sharif", value: "Khanqah Sharif" },
      { label: "Kharian", value: "Kharian" },
      { label: "Khushab", value: "Khushab" },
      { label: "Khuzdar", value: "Khuzdar" },
      { label: "Kohat", value: "Kohat" },
      { label: "Kot Abdul Malik", value: "Kot Abdul Malik" },
      { label: "Kot Addu", value: "Kot Addu" },
      { label: "Kotri", value: "Kotri" },
      { label: "Jauharabad", value: "Jauharabad" },
      { label: "Lahore", value: "Lahore" },
      { label: "Larkana", value: "Lahore" },
      { label: "Lalamusa", value: "Lalamusa" },
      { label: "Layyah", value: "Layyah" },
      { label: "Lawa Chakwal", value: "Lawa Chakwal" },
      { label: "Liaquat Pur", value: "Liaquat Pur" },
      { label: "Lodhran", value: "Lodhran" },
      { label: "Malakwal", value: "Malakwal" },
      { label: "Mamoori", value: "Mamoori" },
      { label: "Mailsi", value: "Mailsi" },
      { label: "Mandi Bahauddin", value: "Mandi Bahauddin" },
      { label: "Mansehra", value: "Mansehra" },
      { label: "Mardan", value: "Mardan" },
      { label: "Mian Channu", value: "Mian Channu" },
      { label: "Mianwali", value: "Mianwali" },
      { label: "Miani", value: "Miani" },
      { label: "Mingora", value: "Mingora" },
      { label: "Mirpur Khas", value: "Mirpur Khas" },
      { label: "Multan", value: "Multan" },
      { label: "Murree", value: "Murree" },
      { label: "Muridke", value: "Muridke" },
      { label: "Muzaffargarh", value: "Muzaffargarh" },
      { label: "Narowal", value: "Narowal" },
      { label: "Nawabshah", value: "Nawabshah" },
      { label: "Nankana Sahib", value: "Nankana Sahib" },
      { label: "Nowshera", value: "Nowshera" },
      { label: "Okara", value: "Okara" },
      { label: "Pakpattan", value: "Pakpattan" },
      { label: "Pattoki", value: "Pattoki" },
      { label: "Peshawar", value: "Peshawar" },
      { label: "Pindi Bhattian", value: "Pindi Bhattian" },
      { label: "Pind Dadan Khan", value: "Pind Dadan Khan" },
      { label: "Pir Mahal", value: "Pir Mahal" },
      { label: "Qaimpur", value: "Qaimpur" },
      { label: "Qila Didar Singh", value: "Qila Didar Singh" },
      { label: "Quetta", value: "Quetta" },
      { label: "Raiwind", value: "Raiwind" },
      { label: "Rajanpur", value: "Rajanpur" },
      { label: "Rahim Yar Khan", value: "Rahim Yar Khan" },
      { label: "Rawalpindi", value: "Rawalpindi" },
      { label: "Renala Khurd", value: "Renala Khurd" },
      { label: "Sadiqabad", value: "Sadiqabad" },
      { label: "Sagri", value: "Sagri" },
      { label: "Sahiwal", value: "Sahiwal" },
      { label: "Sambrial", value: "Sambrial" },
      { label: "Samundri", value: "Samundri" },
      { label: "Sangla Hill", value: "Sangla Hill" },
      { label: "Sarai Alamgir", value: "Sarai Alamgir" },
      { label: "Sargodha", value: "Sargodha" },
      { label: "Shakargarh", value: "Shakargarh" },
      { label: "Shahdadkot", value: "Shahdadkot" },
      { label: "Sheikhupura", value: "Sheikhupura" },
      { label: "Shikarpur", value: "Shikarpur" },
      { label: "Shujaabad", value: "Shujaabad" },
      { label: "Sialkot", value: "Sialkot" },
      { label: "Siranwali", value: "Siranwali" },
      { label: "Sohawa", value: "Sohawa" },
      { label: "Soianwala", value: "Soianwala" },
      { label: "Sukkur", value: "Sukkur" },
      { label: "Swabi", value: "Swabi" },
      { label: "Tando Adam", value: "Tando Adam" },
      { label: "Tando Allah Yar", value: "Tando Allah Yar" },
      { label: "Tando Muhammad Khan", value: "Tando Muhammad Khan" },
      { label: "Tandlianwala", value: "Tandlianwala" },
      { label: "Talagang", value: "Talagang" },
      { label: "Taxila", value: "Taxila" },
      { label: "Toba Tek Singh", value: "Toba Tek Singh" },
      { label: "Turbat", value: "Turbat" },
      { label: "Umer Kot", value: "Umer Kot" },
      { label: "Vehari", value: "Vehari" },
      { label: "Wah Cantonment", value: "Wah Cantonment" },
      { label: "Wazirabad", value: "Wazirabad" },
      { label: "Yazman", value: "Yazman" },
      { label: "Zafarwal", value: "Zafarwal" },
      { label: "Other", value: "Other" },
    ],
  },
];
