/* SAMPLE COLLECTORS */
// export const GET_B2B_PAYMENTS = "GET_B2B_PAYMENTS";
// export const GET_B2B_PAYMENTS_SUCCESS =
//   "GET_B2B_PAYMENTS_SUCCESS";
// export const GET_B2B_PAYMENTS_FAIL = "GET_B2B_PAYMENTS_FAIL";

export const ADD_NEW_B2B_PAYMENT = "ADD_NEW_B2B_PAYMENT";
export const ADD_B2B_PAYMENT_SUCCESS =
  "ADD_B2B_PAYMENT_SUCCESS";
export const ADD_B2B_PAYMENT_FAIL = "ADD_B2B_PAYMENT_FAIL";
