/* SAMPLE COLLECTORS */
// export const GET_DONOR_PAYMENTS = "GET_DONOR_PAYMENTS";
// export const GET_DONOR_PAYMENTS_SUCCESS =
//   "GET_DONOR_PAYMENTS_SUCCESS";
// export const GET_DONOR_PAYMENTS_FAIL = "GET_DONOR_PAYMENTS_FAIL";

export const ADD_NEW_DONOR_PAYMENT = "ADD_NEW_DONOR_PAYMENT";
export const ADD_DONOR_PAYMENT_SUCCESS =
  "ADD_DONOR_PAYMENT_SUCCESS";
export const ADD_DONOR_PAYMENT_FAIL = "ADD_DONOR_PAYMENT_FAIL";
